<script setup lang="ts">
  import hasSlotContent from '../utils/has-slot-content'

  defineProps<{
    columns?: number
    fullWidth?: boolean
  }>()

  const slots = useSlots()
  const hasAside = hasSlotContent(slots.aside)
</script>

<template>
  <div :class="['app-layout', { 'app-layout--one-col': !hasAside, 'app-layout--full-width': fullWidth }]">
    <header class="app-layout__header">
      <slot name="header"/>
    </header>
    <aside
      class="app-layout__aside"
      v-if="hasAside">
      <slot name="aside"/>
    </aside>
    <div class="app-layout__main">
      <slot/>
    </div>
  </div>
</template>

<style scoped>
  .app-layout {
    display: grid;
    grid-template-areas:
      "header"
      "aside"
      "main";
    @media (--md) {
      grid-gap: var(--gap-400);
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "header header header header"
        "aside  main   main   main";
    }
  }

  .app-layout--full-width {
    @media (--md) {
      grid-gap: var(--gap-400);
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "header header header header header"
        "aside  main   main   main   main";
    }
  }

  .app-layout--one-col {
    @media (--md) {
      grid-template-areas:
      "header header header void"
      "main   main   main   void";
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .app-layout__header {
    grid-area: header;
  }

  .app-layout__aside {
    grid-area: aside;
  }

  .app-layout__main {
    grid-area: main;
    position: relative;
  }
</style>
